<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <!-- <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="dataComputed.first_name"
              :label="t('users.firstname')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col> -->

          <!-- <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="dataComputed.last_name"
              :label="t('users.lastname')"
              dense
              outlined
              :readonly="option===2"
              :rules="[]"
            ></v-text-field>
          </v-col> -->

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="dataComputed.fullname"
              :label="t('users.fullname')"
              dense
              outlined
              :readonly="option===2 || isUserComputed"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.categories_ids"
              :label="t('Categories')"
              :items="categories"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              multiple
              persistent-hint
              dense
              outlined
              class="mb-0"
              :append-icon="option === 2 ? null : icons.mdiMenuDown"
              :readonly="option===2 || isUserComputed"
              :rules="[]"
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  label
                  dense
                  :input-value="data.selected"
                  @click="data.select"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <div class="d-flex justify-space-between">
              <v-autocomplete
                v-model="phoneCode"
                dense
                outlined
                :items="phoneCodeOptions"
                item-text="text"
                item-value="value"
                style="
                  width: 35%;
                  max-width: 100px;
                "
                :append-icon="option===2 ? null : icons.mdiMenuDown"
                :readonly="option===2 || isUserComputed"
                hide-details
                :rules="[required]"
                @change="() => phoneNumber = null"
              >
                <template v-slot:item="{ item }">
                  <img
                    :src="item.image"
                    :alt="item.value"
                    width="20"
                    height="auto"
                    class="mr-2"
                  >
                  {{ item.text }}
                </template>
              </v-autocomplete>

              <v-text-field
                v-model="phoneNumber"
                :label="t('users.phone')"
                dense
                outlined
                :disabled="!phoneCode"
                :readonly="option===2 || isUserComputed"
                class="pl-1"
                :rules="[required, validatePhone(phoneNumber, phoneCode)]"
              ></v-text-field>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="dataComputed.email"
              :label="t('users.email')"
              dense
              outlined
              :readonly="option===2 || isUserComputed"
              :rules="[emailValidator]"
            ></v-text-field>
          </v-col>

          <!-- <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2 || isUserApp"
              :rules="[required]"
            ></v-select>
          </v-col> -->

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline,
  mdiCloudUploadOutline,
  mdiMenuDown,
  mdiClose,
  mdiPencilOutline,
  mdiEyeOutline,
} from '@mdi/js'
import {
  ref,
  computed,
  watch,
  onBeforeMount,
  onMounted,
} from '@vue/composition-api'
import { required, emailValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import useCountry from '@core/utils/useCountry'
import usePhone from '@core/utils/usePhone'
import parsePhoneNumber from 'libphonenumber-js'

import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'

import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    facilities: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    isUserApp: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { getCountryCode } = useCountry()
    const {
      phoneCodeOptions,
      initPhoneConfig,
      validatePhones,
    } = usePhone()

    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)
    const isUserComputed = computed(() => props.isUserApp)

    watch([isUserComputed], () => {
    })

    const formDialog = ref(null)
    const isModalOpen = ref(false)
    const initCode = ref('')
    const phoneInputs = ref([
      { value: null, code: null, phone: null },
    ])
    const computedPhoneNumbers = computed(() => phoneInputs.value.filter(e => e.phone).map(e => e.phone).join(', '))

    const phoneCode = ref('')
    const phoneNumber = ref('')

    const dataAcount = ref({})
    const initialData = {
      name: null,
      note: null,
      playing_time: null,
      status: null,
    }

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const onSaveAction = () => {
      if (form.value.validate()) {
        dataComputed.value.phone_numbers = phoneInputs.value.filter(e => e.phone).map(e => e.phone)
        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
      dataAcount.value = Object.assign(initialData, {
        name: null,
        note: null,
        playing_time: null,
        status: null,
      })
      emit('updateIsUserApp', false)
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const addPhoneNumber = () => {
      if (phoneInputs.value.length < 30) {
        phoneInputs.value.push({ value: null, code: initCode.value, phone: null })
      }
    }

    const deletePhoneNumber = index => {
      phoneInputs.value.splice(index, 1)
    }

    const validatePhone = (e, code) => {
      if (code && e) {
        const phoneNumberJS = parsePhoneNumber(e, code)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            dataComputed.value.phone_number = phoneNumberJS.number
            dataComputed.value.phone_code = phoneNumberJS.country
            dataComputed.value.phone_prefix = `+${phoneNumberJS.countryCallingCode}`

            return true
          }
        }
      }

      return 'Invalid number'
    }

    const initPhone = () => {
      setTimeout(() => {
        if (props.option === 1) {
          const countryCode = getCountryCode()
          if (countryCode) {
            const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
            if (codeFinded) initCode.value = countryCode
            else initCode.value = 'US'
          }
          phoneInputs.value[0].code = initCode.value
          phoneCode.value = initCode.value

          dataComputed.value.status = 'A'
        }

        if (props.option !== 1) {
          if (props.dataParams.phone_number) {
            const config = initPhoneConfig(props.dataParams.phone_number)
            phoneCode.value = config.code
            phoneNumber.value = config.phone_number
          }
        }
      }, 1000)
    }

    watch([isModalOpen], () => {
      if (isModalOpen.value) {
        setTimeout(() => {
          const btn = document.getElementById('addBtnForm')
          btn.addEventListener('click', addPhoneNumber)
        }, 200)
      }
    })

    watch([isUserComputed], () => {
      if (!isUserComputed.value) initPhone()
    })

    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    onMounted(async () => {
      await initPhone()
    })

    return {
      // data
      form,
      dataAcount,
      initialData,

      formDialog,
      isModalOpen,
      phoneInputs,
      computedPhoneNumbers,
      phoneCodeOptions,

      phoneCode,
      phoneNumber,

      // computed
      dataComputed,
      statusOptions,
      isUserComputed,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,
      addPhoneNumber,
      deletePhoneNumber,

      // validations
      required,
      emailValidator,
      integerValidator,
      validatePhone,
      validatePhones,

      // i18n
      t,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiMenuDown,
        mdiClose,
        mdiPencilOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>
