<template>
  <v-card
    id="contract-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <contact-tab-general
          v-if="isContact"
          :data-params="contactData"
          :tab="tab"
          :option="option"
          :is-user-app="isUserApp"
          :facilities="facilities"
          :categories="categories"
          @updateIsUserApp="e => isUserApp = e"
          @changeTab="changeTab"
          @save="saveAction"
        ></contact-tab-general>

        <contact-category-tab-general
          v-if="!isContact"
          :data-params="contactCategoryData"
          :tab="tab"
          :option="option"
          :is-user-app="isUserApp"
          :facilities="facilities"
          @updateIsUserApp="e => isUserApp = e"
          @changeTab="changeTab"
          @save="saveAction"
        ></contact-category-tab-general>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import {
  getFacilities,
  getContactCategoryActiveFacility,
  createContact,
  editContact,
  createContactCategory,
  editContactCategory,
  getContact,
  getContactCategory,
} from '@api'
import useSelectOptions from '@core/utils/useSelectOptions'

// demos
import ContactTabGeneral from './ContactTabGeneral.vue'
import ContactCategoryTabGeneral from './ContactCategoryTabGeneral.vue'

export default {
  components: {
    ContactTabGeneral,
    ContactCategoryTabGeneral,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const {
      configOrganization,
      configFacility,
    } = useSelectOptions()

    const tab = ref(0)
    const isContact = ref(true)
    const option = ref(1)
    const contactId = ref(null)
    const isUserApp = ref(false)
    const pageData = ref()
    const filterData = ref()
    const contactData = ref({
      facility_id: null,
      user_app_id: null,
      fullname: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      categories_ids: [],
    })
    const contactCategoryData = ref({
      name: null,
      description: null,
      facility_id: null,
      status: 'A',
    })
    const postData = ref({
      facility_id: null,
      user_app_id: null,
      fullname: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      categories_ids: [],
    })
    const putData = ref({
      facility_id: null,
      user_app_id: null,
      fullname: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      categories_ids: [],
    })
    const postDataCategory = ref({
      name: null,
      description: null,
      facility_id: null,
      status: 'A',
    })
    const putDataCategory = ref({
      name: null,
      description: null,
      facility_id: null,
      status: null,
    })
    const facilities = ref([])
    const categories = ref([])

    // tabs
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    const goBack = () => {
      router.replace({
        name: 'views-contact-list',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const saveAction = async data => {
      if (!option.value) {
        goBack()
      }

      let body = {}
      let resp = null
      if (option.value === 1) {
        if (isContact.value) {
          body = {
            facility_id: configFacility.value,
            user_app_id: data.user_app_id,
            fullname: data.fullname,
            phone_code: data.phone_code,
            phone_prefix: data.phone_prefix,
            phone_number: data.phone_number,
            email: data.email,
            categories_ids: data.categories_ids,
          }
          resp = await createContact(body)
        } else {
          postDataCategory.value = Object.assign(postDataCategory.value, {
            name: data.name,
            description: data.description,
            facility_id: configFacility.value,
            status: data.status,
          })
          resp = await createContactCategory(postDataCategory.value)
        }
      }

      if (option.value === 3) {
        if (isContact.value) {
          body = {
            facility_id: configFacility.value,
            user_app_id: data.user_app_id,
            fullname: data.fullname,
            phone_code: data.phone_code,
            phone_prefix: data.phone_prefix,
            phone_number: data.phone_number,
            email: data.email,
            categories_ids: data.categories_ids,
          }
          resp = await editContact(body, data.id)
        } else {
          putDataCategory.value = Object.assign(putDataCategory.value, {
            name: data.name,
            description: data.description,
            facility_id: configFacility.value,
            status: data.status,
          })
          resp = await editContactCategory(putDataCategory.value, data.id)
        }
      }

      if (resp.ok) {
        goBack()
        success(resp.message)
      } else {
        error(resp.message.text)
        if (resp.message.code === 'CON004') {
          contactData.value = {
            ...contactData.value,
            fullname: resp.data.fullname,
            email: resp.data.email,
            phone_number: resp.data.phone_number,
            user_app_id: resp.data.user_app_id,
          }
          isUserApp.value = true
        }
      }
    }

    const cancelAction = () => {
      tab.value = 0
      contactData.value = {}
      contactCategoryData.value = {}

      // tabs.value[1].disabled = true
      // tabs.value[2].disabled = true
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option || 2
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams
      contactId.value = route.value.params.id
      isContact.value = route.value.params.isContact ? route.value.params.isContact === 'contact' : false

      if (contactId.value) {
        let resp = null
        if (isContact.value) resp = await getContact(contactId.value)
        else resp = await getContactCategory(contactId.value)

        if (resp.ok) {
          if (isContact.value) contactData.value = resp.data
          else contactCategoryData.value = resp.data
        } else goBack()
      } else option.value = 1

      const response = await getFacilities()
      facilities.value = response.data
      const responseCategories = await getContactCategoryActiveFacility(configFacility.value || 0)
      categories.value = responseCategories.data
    })

    return {
      tab,
      tabs,
      isContact,
      isUserApp,
      option,
      contactId,
      contactData,
      contactCategoryData,
      postData,
      putData,
      postDataCategory,
      putDataCategory,
      facilities,
      categories,
      pageData,
      filterData,

      configOrganization,
      configFacility,

      changeTab,
      saveAction,
      cancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
